// document.addEventListener("turbolinks:load", ...):
// Listens for the turbolinks:load event, which is triggered whenever Turbolinks replaces the DOM after navigation.
//     initializeModalEvents:
// Attaches the click event listeners to the modal's open and close buttons after each Turbolinks
// page load or partial update.


document.addEventListener("turbolinks:load", initializeModalEvents);

function initializeModalEvents() {
    const openButton = document.getElementById("open-modal-btn");
    const closeButton = document.getElementById("close-modal-btn");

    openButton?.addEventListener("click", openModal);
    closeButton?.addEventListener("click", closeModal);
}

function openModal() {
    const modal = document.getElementById("filter-modal");
    modal.classList.remove("hidden");
}

// This script saves the scroll position before Turbolinks caches the page and restores it after the page reloads
function closeModal() {
    const modal = document.getElementById("filter-modal");
    modal.classList.add("hidden");
}

document.addEventListener('click', (event) => {
    const modal = document.getElementById('filter-modal');
    if (event.target === modal) {
        closeModal();
    }
});

document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
        closeModal();
    }
});

// This script saves the scroll position before Turbolinks caches the page and restores it after the page reloads
// document.addEventListener("turbolinks:before-cache", () => {
//     window.scrollPosition = window.scrollY;
//     console.log(window.scrollY)
// });
//
// document.addEventListener("turbolinks:load", () => {
//     console.log("load" + window.scrollY)
//     if (typeof window.scrollPosition !== "undefined") {
//         console.log("not null" + window.scrollY)
//         window.scrollTo(0, window.scrollPosition);
//         window.scrollPosition = undefined; // Clear after restoring
//     }
// });

document.addEventListener("turbolinks:before-cache", () => {
    // Check if the "Clear Filters" button was clicked
    if (window.clearFiltersClicked) {
        window.scrollPosition = window.scrollY;
    }
});

document.addEventListener("turbolinks:load", () => {
    // Restore scroll position only if it was stored
    if (typeof window.scrollPosition !== "undefined") {
        window.scrollTo(0, window.scrollPosition);
        window.scrollPosition = undefined; // Clear after restoring
        window.clearFiltersClicked = false; // Reset the flag
    }
});

document.addEventListener("DOMContentLoaded", () => {
    // Add event listener to the "Clear Filters" button
    const clearFiltersBtn = document.getElementById("clear-filters-btn");
    if (clearFiltersBtn) {
        clearFiltersBtn.addEventListener("click", () => {
            window.clearFiltersClicked = true; // Set the flag
        });
    }
});
